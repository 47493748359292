// Home.vue is his parent
<template>
<!--Like a wrapper, it's gonna display the DisplayDetails when we click. -->
  <router-link
    class="event-link" :to="{ name: 'EventDetails', params: { id: event.id } }"> <!--Object name of our router-->
	<div class="event-card">
		<span> @ {{ event.time }} on {{ event.date }}</span>
		<h4>{{ event.title }}</h4>
	</div>
</router-link>
</template>

<script>
export default {
  name: "EventCard",
  props: {
    event: Object
  }
}
</script>

<style scoped>

.event-card {
  padding: 20px;
  width: 250px;
  cursor: pointer;
  border: 0,5px solid #bd95b5;
  margin-bottom: 18px;
}

.event-card:hover {
  transform: scale(1.01);
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.6);
}

</style>
